<script setup lang="ts">
import { useAuth0 } from '@auth0/auth0-vue'
const { isAuthenticated, user } = useAuth0()

defineSlots<{
  default: {
    isAuthenticated: typeof isAuthenticated
    user: typeof user
  }
}>()
</script>

<template>
  <div>
    <slot v-bind="{ isAuthenticated, user }" />
  </div>
</template>
